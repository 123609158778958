.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button{
  background-color: #274472;
  border: none;
  /* width: 98%; */
  color: #ECECEC;
  padding: 5px;
}

.otp-input-field input{
  height: 45px;
  width: 100%;
  font-size: 1.125rem;
  /* margin-left: 15px; */
  
  }
  /* input{
   margin: 10px;
  } */
  .otp-card{
  width: 500px;
  }
  .otp-header{
     /* margin: 20px 70px ; */
     font-weight: 500;
  } 
  /* img{
      width: 200px;
      
  } */
  .otp-input-field input::-webkit-inner-spin-button,
  .otp-input-field input::-webkit-outer-spin-button {
   display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body{
  background-color: #ECECEC;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.align-items{
  align-items: center !important;
}
.card-body p{
  margin-bottom: 20px;
}
